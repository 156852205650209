import { ChainId, CurrencyAmount, JSBI, Token, TokenAmount, Pair, WETH } from '@cronosdex/sdk'
import { useMemo } from 'react'
import {
  ATOM,
  BCRO,
  BEAR,
  BNB,
  BOBZ,
  BOO,
  BULL,
  BURNCRO,
  BUSD,
  CPB,
  CPBR,
  CROARMY,
  CRONK,
  CRONOSVERSE,
  CRX,
  CSAI,
  CUJO,
  DAOLAB,
  FEED,
  GAT,
  ICY,
  LADY,
  LIGHTWAVE,
  MAI_NATIVE,
  ORC,
  ROAST,
  SHINU,
  STEAK,
  TATAS_TWISTED_TIME,
  THNDR,
  UNI,
  USDC,
  USDT,
  VIPICY,
  WRAPPEDETHER
} from '../../constants'
import { BOOSTED_STAKING_REWARDS_INTERFACE } from '../../constants/abis/boosted-staking-rewards'
import {
  ARMY_100K_TOKEN_NFT,
  BURN_CRO_BEARS_AND_BULLS,
  COCKHEADZ_OG_FOUNDER,
  COWZ,
  CRONK_OG,
  CROVILIANS,
  CRXILLION,
  DR_DAO_OG,
  ICY_NFT,
  LADY_SHINU,
  LA_BRIGADE_DE_CUISINE,
  LIGHTWAVE_BOOSTER,
  PUMPED_SHINU,
  SPACE_CRXILLIONS,
  THUNDER_NFT,
  TIME_TROPS
} from '../../constants/collections'
import { useActiveWeb3React } from '../../hooks'
import { NEVER_RELOAD, useMultipleContractSingleData } from '../multicall/hooks'
import {
  ARMY_100K_TOKEN_NFT_INFO,
  COCKHEADZ_OG_FOUNDER_INFO,
  COWZ_INFO,
  CRONK_OG_INFO,
  CROVILIANS_INFO,
  CRXILLION_INFO,
  DR_DAO_OG_INFO,
  ICY_NFT_INFO,
  LADY_SHINU_INFO,
  LA_BRIGADE_DE_CUISINE_INFO,
  LIGHTWAVE_BOOSTER_INFO,
  ProjectInfo,
  PUMPED_SHINU_INFO,
  SPACE_CRXILLION_INFO,
  THUNDER_NFT_INFO,
  TIME_TROPS_INFO,
  BURN_CRO_BEARS_AND_BULLS_INFO
} from '../nftStake/projects'
import { LP_STAKE_TYPES, LP_STATUS_TYPES } from '../stake/hooks'
import { tryParseAmount } from '../swap/hooks'

export const STAKING_GENESIS = 1633636800

export const REWARDS_DURATION_DAYS = 28

interface INTERACTION_INFO {
  payable: boolean
  depositFee: number | undefined
  withdrawFee: number | undefined
  claimFee: number | undefined
}

// TODO add staking rewards addresses h ere
export const LP_BOOST_STAKING_REWARDS_INFO: {
  [chainId in ChainId]?: {
    collection: Token
    projectInfo: ProjectInfo
    tokens: [Token, Token]
    stakingRewardAddress: string
    maxBoosterCount: number
    boostDiv: number
    interactionInfo: INTERACTION_INFO
    rewardToken: Token
    round: number
    type: LP_STAKE_TYPES
  }[]
} = {
  [ChainId.CRONOSMAINNET]: [
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [CRX, WETH[ChainId.CRONOSMAINNET]],
      stakingRewardAddress: '0xAAbA1579f6f8488f3CADacb8513CF1200AD52cC4',
      interactionInfo: { payable: false, depositFee: undefined, withdrawFee: undefined, claimFee: undefined },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: CRX,
      round: 5,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [CRX, USDC],
      stakingRewardAddress: '0xEFF338346eE3676D7e38E3Ff5ac12a18123E44CA',
      interactionInfo: { payable: false, depositFee: undefined, withdrawFee: undefined, claimFee: undefined },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: CRX,
      round: 5,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [CRX, USDT],
      stakingRewardAddress: '0x3Bb3bAb56780f7623b36Bd5B91bE7fcf9B86b125',
      interactionInfo: { payable: false, depositFee: undefined, withdrawFee: undefined, claimFee: undefined },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: CRX,
      round: 5,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], USDC],
      stakingRewardAddress: '0x65A88434cb5316Ce4Aeb8efA28de48BEc05E72Cb',
      interactionInfo: { payable: false, depositFee: undefined, withdrawFee: undefined, claimFee: undefined },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: CRX,
      round: 5,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [BUSD, USDC],
      stakingRewardAddress: '0xb5257C4cA8E3C0571c27811d7dBAA4F6C2390fbc',
      interactionInfo: { payable: false, depositFee: undefined, withdrawFee: undefined, claimFee: undefined },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: CRX,
      round: 5,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], BNB],
      stakingRewardAddress: '0x90DaCF4156333060Ef2e56321C6437b3D0C49380',
      interactionInfo: { payable: false, depositFee: undefined, withdrawFee: undefined, claimFee: undefined },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: CRX,
      round: 5,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], ATOM],
      stakingRewardAddress: '0x4445EDd281854d7E9EDA2096595F6755a96bAd18',
      interactionInfo: { payable: false, depositFee: undefined, withdrawFee: undefined, claimFee: undefined },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: CRX,
      round: 5,
      type: LP_STAKE_TYPES.LEGACY
    },
    // {
    //     collection: CRXILLION,
    //     projectInfo: CRXILLION_INFO,
    //     tokens: [WETH[ChainId.CRONOSMAINNET], CRYSTL],
    //     stakingRewardAddress: '0x56d00E9f9679B7942CCE0e7Dea270e970602Df37',
    //     interactionInfo: { payable: false,  depositFee: undefined, withdrawFee:undefined, claimFee:undefined },
    //     maxBoosterCount: 3,
    //     boostDiv: 1000,
    //     rewardToken: CRX,
    //     round: 5,
    //     type: LP_STAKE_TYPES.LEGACY
    // },
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], CUJO],
      stakingRewardAddress: '0x3991B018a08C9ceA5d57b67B7F3036324741A744',
      interactionInfo: { payable: false, depositFee: undefined, withdrawFee: undefined, claimFee: undefined },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: CRX,
      round: 5,
      type: LP_STAKE_TYPES.LEGACY
    },

    //////////////
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [CRX, WETH[ChainId.CRONOSMAINNET]],
      stakingRewardAddress: '0x1b6629e5B1a0F2339e315D859F75fCD5FeD21c1C',
      interactionInfo: { payable: false, depositFee: undefined, withdrawFee: undefined, claimFee: undefined },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: CRX,
      round: 6,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [CRX, USDC],
      stakingRewardAddress: '0x24D71B4A092C22E294C65B1c985BDCC24626a89e',
      interactionInfo: { payable: false, depositFee: undefined, withdrawFee: undefined, claimFee: undefined },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: CRX,
      round: 6,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [CRX, USDT],
      stakingRewardAddress: '0x23b3CAD1E030304872be5ED813Bd8e0E3A11FF9c',
      interactionInfo: { payable: false, depositFee: undefined, withdrawFee: undefined, claimFee: undefined },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: CRX,
      round: 6,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [USDC, USDT],
      stakingRewardAddress: '0xc2335FE813c9b1F9F876a205cEcDF57B430C33da',
      interactionInfo: { payable: false, depositFee: undefined, withdrawFee: undefined, claimFee: undefined },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: CRX,
      round: 6,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], USDC],
      stakingRewardAddress: '0x96bee7Da17247E99aDE918C3eb9431A7f75F80E8',
      interactionInfo: { payable: false, depositFee: undefined, withdrawFee: undefined, claimFee: undefined },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: CRX,
      round: 6,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      collection: BURN_CRO_BEARS_AND_BULLS,
      projectInfo: BURN_CRO_BEARS_AND_BULLS_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], BULL],
      stakingRewardAddress: '0x9Af2D629BeF3b7BADf2FCd6FDBaA2cec449A544e',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: BULL,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: BURN_CRO_BEARS_AND_BULLS,
      projectInfo: BURN_CRO_BEARS_AND_BULLS_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], BEAR],
      stakingRewardAddress: '0x8D61e973DFD8B89a92fa211a7e43499e4A6ff584',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: BEAR,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [MAI_NATIVE, USDC],
      stakingRewardAddress: '0x192f1050Af05876eCE5B969cf4579521F505fe1A',
      interactionInfo: { payable: false, depositFee: undefined, withdrawFee: undefined, claimFee: undefined },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: CRX,
      round: 6,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], USDT],
      stakingRewardAddress: '0xD883563b483C3A0762a53bd7b703Afaa583A45F1',
      interactionInfo: { payable: false, depositFee: undefined, withdrawFee: undefined, claimFee: undefined },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: CRX,
      round: 6,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], WRAPPEDETHER],
      stakingRewardAddress: '0x35834Dd590cA25064CeCC6968D429bA78078Bd25',
      interactionInfo: { payable: false, depositFee: undefined, withdrawFee: undefined, claimFee: undefined },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: CRX,
      round: 6,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], CROARMY],
      stakingRewardAddress: '0xB0924a187feeaeC0F503BF3b7Cc81c7c2A492EF9',
      interactionInfo: { payable: false, depositFee: undefined, withdrawFee: undefined, claimFee: undefined },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: CROARMY,
      round: 6,
      type: LP_STAKE_TYPES.LEGACY
    },
    {
      collection: SPACE_CRXILLIONS,
      projectInfo: SPACE_CRXILLION_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], ORC],
      stakingRewardAddress: '0x1912C85662E904aa1585EEC8570Ab1db89B5Ac3b',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: ORC,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: SPACE_CRXILLIONS,
      projectInfo: SPACE_CRXILLION_INFO,
      tokens: [CSAI, ORC],
      stakingRewardAddress: '0xD98Ac8b585A8Bfe7609Da66aCe11eb52e7D305d3',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 0,
      boostDiv: 1000,
      rewardToken: CSAI,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: SPACE_CRXILLIONS,
      projectInfo: SPACE_CRXILLION_INFO,
      tokens: [CSAI, BOBZ],
      stakingRewardAddress: '0x4B5696D9aB8A1dAd9e65bE76A813Fdf7eb94Ab0C',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 0,
      boostDiv: 1000,
      rewardToken: CSAI,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: CROVILIANS,
      projectInfo: CROVILIANS_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], CRONOSVERSE],
      stakingRewardAddress: '0xd1e41b824C0a9F500CbD604e149E07503D2EDE93',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: CRONOSVERSE,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], CROARMY],
      stakingRewardAddress: '0xbf0F887F8992A6262bfe2570c2da3ec229EED40c',
      interactionInfo: { payable: false, depositFee: undefined, withdrawFee: undefined, claimFee: undefined },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: CROARMY,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], ICY],
      stakingRewardAddress: '0xC66a5b23CC824dA7341d694F891A74F8e5a55fCf',
      interactionInfo: { payable: false, depositFee: undefined, withdrawFee: undefined, claimFee: undefined },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: ICY,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: ARMY_100K_TOKEN_NFT,
      projectInfo: ARMY_100K_TOKEN_NFT_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], CROARMY],
      stakingRewardAddress: '0xEf0958B504272225e7a3fe0bEC4CE8651fEA5d96',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: CROARMY,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: CRONK_OG,
      projectInfo: CRONK_OG_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], CRONK],
      stakingRewardAddress: '0xbd71f54e44619a3d93dc9d0588b89063b8ed9dc1',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: WETH[ChainId.CRONOSMAINNET],
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: LA_BRIGADE_DE_CUISINE,
      projectInfo: LA_BRIGADE_DE_CUISINE_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], STEAK],
      stakingRewardAddress: '0x21121Fadd861e4eD8C035c4d69d3eB0aDcAd84d6',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: STEAK,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: COWZ,
      projectInfo: COWZ_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], ROAST],
      stakingRewardAddress: '0x0b1d933BC50c086e3348E98d6058330b8B4fFc55',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: ROAST,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: THUNDER_NFT,
      projectInfo: THUNDER_NFT_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], THNDR],
      stakingRewardAddress: '0x9bB9cF23567cfF7b3764F5378F3e40f9f2E29EB8',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: THNDR,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: PUMPED_SHINU,
      projectInfo: PUMPED_SHINU_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], SHINU],
      stakingRewardAddress: '0xB69B3DAf6E4EcB8fcB08ca17346B994aF22d80d9',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: SHINU,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: LADY_SHINU,
      projectInfo: LADY_SHINU_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], LADY],
      stakingRewardAddress: '0xee171A2af9B4688A51879c6184ad5Cafb86A80BE',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: LADY,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], BOO],
      stakingRewardAddress: '0x820a985057A1644FDa6Ed6d6c5Cb0b0493932938',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 0,
      boostDiv: 1000,
      rewardToken: BOO,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: COCKHEADZ_OG_FOUNDER,
      projectInfo: COCKHEADZ_OG_FOUNDER_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], FEED],
      stakingRewardAddress: '0x5D173CD1faF703269E3c7483625Be7955E315bd1',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: FEED,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], SHINU],
      stakingRewardAddress: '0xc70f9F76e7d1Ba6De6Edf1b239C0f15F13b4859c',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 0,
      boostDiv: 1000,
      rewardToken: SHINU,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: DR_DAO_OG,
      projectInfo: DR_DAO_OG_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], DAOLAB],
      stakingRewardAddress: '0xA8C90FCba9EADf78F4f14AD0C4309dca6d5E2ff2',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: DAOLAB,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: TIME_TROPS,
      projectInfo: TIME_TROPS_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], TATAS_TWISTED_TIME],
      stakingRewardAddress: '0xCAB41B94e0eBDB448a9b5c89694C6830276DF8Cc',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: TATAS_TWISTED_TIME,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: ICY_NFT,
      projectInfo: ICY_NFT_INFO,
      tokens: [BCRO, ICY],
      stakingRewardAddress: '0x139817dc868b37240d8fc2a90f1e780b283f05bf',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: VIPICY,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: LIGHTWAVE_BOOSTER,
      projectInfo: LIGHTWAVE_BOOSTER_INFO,
      tokens: [USDC, LIGHTWAVE],
      stakingRewardAddress: '0x6C0F4dDF6DC0b178e3A12ca3f877bf634e5c2437',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: LIGHTWAVE,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], CPB],
      stakingRewardAddress: '0x94D89776818de2f584254d6D47923Be5e31ace26',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 0,
      boostDiv: 1000,
      rewardToken: CPBR,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: SPACE_CRXILLIONS,
      projectInfo: SPACE_CRXILLION_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], BURNCRO],
      stakingRewardAddress: '0x3eAf6da9dc158bf89C88F399F47A32E0Bd815DF7',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 3,
      boostDiv: 1000,
      rewardToken: WETH[ChainId.CRONOSMAINNET],
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    {
      collection: CRXILLION,
      projectInfo: CRXILLION_INFO,
      tokens: [WETH[ChainId.CRONOSMAINNET], GAT],
      stakingRewardAddress: '0xABcF9aA704b0e48f07bfa08b533499388fEeb251',
      interactionInfo: { payable: true, depositFee: 1, withdrawFee: 1, claimFee: 1 },
      maxBoosterCount: 0,
      boostDiv: 1000,
      rewardToken: GAT,
      round: 6,
      type: LP_STAKE_TYPES.RENEWABLE
    },
    // {
    //     collection: CRXILLION,
    //     projectInfo: CRXILLION_INFO,
    //     tokens: [TCN, USDC],
    //     stakingRewardAddress: '0x0125F3757b205605dEF41F11E92620D0593A49A5',
    //     interactionInfo: { payable: false,  depositFee: undefined, withdrawFee:undefined, claimFee:undefined },
    //     rewardToken: TCN,
    //     round: 6
    //   },
    // {
    //     collection: TESTCRXILLION,
    //     projectInfo: CRXILLION_INFO,
    //     tokens: [CRX, USDC],
    //     stakingRewardAddress: '0x5E0eb39451B0B2ed2896FFC27CF2530F6E23F413',
    //     interactionInfo: { payable: false,  depositFee: undefined, withdrawFee:undefined, claimFee:undefined },
    //     rewardToken: CRX,
    //     round: 4
    //},
  ]
}

export interface LPBoostStakingInfo {
  // collection
  collection: Token
  // project info
  projectInfo: ProjectInfo
  // the address of the reward contract
  stakingRewardAddress: string
  // type
  type: LP_STAKE_TYPES
  // pool status
  poolStatus: LP_STATUS_TYPES
  // max booster count
  maxBoosterCount: number
  // boost permillage
  boostPermillage: number
  // boost divider
  boostDiv: number
  // boosters
  boosters: any[]
  // the tokens involved in this pair
  tokens: [Token, Token]
  // interaction info
  interactionInfo: INTERACTION_INFO
  // the reward tokens
  rewardToken: Token
  // round info
  round: number
  // the amount of token currently staked, or undefined if no account
  stakedAmount: TokenAmount
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: TokenAmount
  // earned from boost
  earnedFromBoostAmount: TokenAmount
  // the total amount of token staked in the contract
  totalStakedAmount: TokenAmount
  // the amount of token distributed per second to all LPs, constant
  totalRewardRate: TokenAmount
  // the current amount of token distributed to the active account per second.
  // equivalent to percent of total supply * reward rate
  rewardRate: TokenAmount
  // user share
  userShare: number
  // when the period ends
  periodFinish: Date | undefined
  // calculates a hypothetical amount of token distributed to the active account per second.
  getHypotheticalRewardRate: (
    stakedAmount: TokenAmount,
    totalStakedAmount: TokenAmount,
    totalRewardRate: TokenAmount
  ) => TokenAmount
}

// gets the staking info from the network for the active chain id
export function useLPBoostStakingInfo(
  roundInfo?: number | null,
  rewardToFilterBy?: string | null
): LPBoostStakingInfo[] {
  const { chainId, account } = useActiveWeb3React()
  const info = useMemo(
    () =>
      chainId
        ? LP_BOOST_STAKING_REWARDS_INFO[chainId]?.filter(
            stakingRewardInfo =>
              rewardToFilterBy === undefined ? true : rewardToFilterBy === stakingRewardInfo.stakingRewardAddress // rewardToFilterBy.equals(stakingRewardInfo.token)
          ) ?? []
        : [],
    [chainId, roundInfo, rewardToFilterBy]
  )

  const uni = chainId ? UNI[chainId] : undefined

  const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

  const accountArg = useMemo(() => [account ?? undefined], [account])

  // get all the info from the staking rewards contracts
  const balances = useMultipleContractSingleData(
    rewardsAddresses,
    BOOSTED_STAKING_REWARDS_INTERFACE,
    'balanceOf',
    accountArg
  )
  const earnedAmounts = useMultipleContractSingleData(
    rewardsAddresses,
    BOOSTED_STAKING_REWARDS_INTERFACE,
    'earned',
    accountArg
  )
  const totalSupplies = useMultipleContractSingleData(
    rewardsAddresses,
    BOOSTED_STAKING_REWARDS_INTERFACE,
    'totalSupply'
  )

  const boosters = useMultipleContractSingleData(
    rewardsAddresses,
    BOOSTED_STAKING_REWARDS_INTERFACE,
    'boostersOf',
    accountArg
  )
  const earnedFromBoostAmounts = useMultipleContractSingleData(
    rewardsAddresses,
    BOOSTED_STAKING_REWARDS_INTERFACE,
    'earnedFromBoost',
    accountArg
  )

  const boostPermillage = useMultipleContractSingleData(
    rewardsAddresses,
    BOOSTED_STAKING_REWARDS_INTERFACE,
    'boostPermillage',
    undefined,
    NEVER_RELOAD
  )

  // tokens per second
  const rewardRates = useMultipleContractSingleData(
    rewardsAddresses,
    BOOSTED_STAKING_REWARDS_INTERFACE,
    'rewardRate',
    undefined,
    NEVER_RELOAD
  )
  const periodFinishes = useMultipleContractSingleData(
    rewardsAddresses,
    BOOSTED_STAKING_REWARDS_INTERFACE,
    'periodFinish',
    undefined,
    NEVER_RELOAD
  )

  return useMemo(() => {
    if (!chainId || !uni) return []

    return rewardsAddresses.reduce<LPBoostStakingInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account
      const balanceState = balances[index]
      const earnedAmountState = earnedAmounts[index]

      // these get fetched regardless of account
      const totalSupplyState = totalSupplies[index]
      const rewardRateState = rewardRates[index]
      const periodFinishState = periodFinishes[index]

      const boostersState = boosters[index]
      const earnedFromBoostAmountState = earnedFromBoostAmounts[index]
      const boostPermillageState = boostPermillage[index]

      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading &&
        !earnedFromBoostAmountState?.loading &&
        !boostersState?.loading &&
        // always need these
        totalSupplyState &&
        !totalSupplyState.loading &&
        rewardRateState &&
        !rewardRateState.loading &&
        periodFinishState &&
        !periodFinishState.loading &&
        boostPermillageState &&
        !boostPermillageState.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error ||
          boostersState?.error ||
          earnedFromBoostAmountState?.error ||
          totalSupplyState.error ||
          rewardRateState.error ||
          periodFinishState.error ||
          boostPermillageState.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const tokens = info[index].tokens
        const dummyPair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0'))

        // check for account, if no account set to 0

        const stakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
        const totalStakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(totalSupplyState?.result?.[0]))
        const totalRewardRate = new TokenAmount(info[index].rewardToken, JSBI.BigInt(rewardRateState?.result?.[0]))

        // booster variables
        const boosters = boostersState?.result?.[0] ?? []
        const maxBoosterCount = info[index].maxBoosterCount
        const boostPermillage = parseInt(boostPermillageState?.result?.[0])
        const boostDiv = info[index].boostDiv

        const getHypotheticalRewardRate = (
          stakedAmount: TokenAmount,
          totalStakedAmount: TokenAmount,
          totalRewardRate: TokenAmount
        ): TokenAmount => {
          return new TokenAmount(
            info[index].rewardToken,
            JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
              ? JSBI.divide(JSBI.multiply(totalRewardRate.raw, stakedAmount.raw), totalStakedAmount.raw)
              : JSBI.BigInt(0)
          )
        }

        const individualRewardRate = getHypotheticalRewardRate(stakedAmount, totalStakedAmount, totalRewardRate)

        const periodFinishMs = periodFinishState?.result?.[0]?.mul(1000)?.toNumber()
        const isRewardEnded = new Date().getTime() > periodFinishMs ? true : false
        const userShare = totalStakedAmount.greaterThan('0')
          ? parseFloat(stakedAmount?.divide(totalStakedAmount).toFixed(6))
          : 0

        let poolStatus
        if (info[index].type === LP_STAKE_TYPES.LEGACY && isRewardEnded) poolStatus = LP_STATUS_TYPES.FINISHED
        else if (info[index].type === LP_STAKE_TYPES.LEGACY && !isRewardEnded) poolStatus = LP_STATUS_TYPES.LIVE
        else if (isRewardEnded) poolStatus = LP_STATUS_TYPES.HALTED
        else poolStatus = LP_STATUS_TYPES.LIVE

        memo.push({
          collection: info[index].collection,
          projectInfo: info[index].projectInfo,
          stakingRewardAddress: rewardsAddress,
          maxBoosterCount: maxBoosterCount,
          boostPermillage: boostPermillage,
          boostDiv: boostDiv,
          boosters: boosters,
          interactionInfo: info[index].interactionInfo,
          tokens: info[index].tokens,
          rewardToken: info[index].rewardToken,
          round: info[index].round,
          type: info[index].type,
          poolStatus: poolStatus,
          periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          earnedAmount: new TokenAmount(info[index].rewardToken, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
          earnedFromBoostAmount: new TokenAmount(
            info[index].rewardToken,
            JSBI.BigInt(earnedFromBoostAmountState?.result?.[0] ?? 0)
          ),
          rewardRate: individualRewardRate,
          userShare: userShare,
          totalRewardRate: totalRewardRate,
          stakedAmount: stakedAmount,
          totalStakedAmount: totalStakedAmount,
          getHypotheticalRewardRate
        })
      }
      return memo
    }, [])
  }, [
    balances,
    chainId,
    earnedAmounts,
    info,
    periodFinishes,
    rewardRates,
    rewardsAddresses,
    totalSupplies,
    uni,
    boostPermillage,
    earnedFromBoostAmounts,
    boosters
  ])
}

export function useTotalUniEarned(): TokenAmount | undefined {
  const { chainId } = useActiveWeb3React()
  const uni = chainId ? UNI[chainId] : undefined
  const stakingInfos = useLPBoostStakingInfo()

  return useMemo(() => {
    if (!uni) return undefined
    return (
      stakingInfos?.reduce(
        (accumulator, stakingInfo) => accumulator.add(stakingInfo.earnedAmount),
        new TokenAmount(uni, '0')
      ) ?? new TokenAmount(uni, '0')
    )
  }, [stakingInfos, uni])
}

// based on typed value
export function useLPBoostDerivedStakeInfo(
  typedValue: string,
  stakingToken: Token,
  userLiquidityUnstaked: TokenAmount | undefined
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingToken)

  const parsedAmount =
    parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.raw, userLiquidityUnstaked.raw)
      ? parsedInput
      : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}

// based on typed value
export function useLPBoostDerivedUnstakeInfo(
  typedValue: string,
  stakingAmount: TokenAmount
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingAmount.token)

  const parsedAmount = parsedInput && JSBI.lessThanOrEqual(parsedInput.raw, stakingAmount.raw) ? parsedInput : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}
